import $ from 'jquery';
import { LOCKS } from './globalVars';
import Cookies from 'universal-cookie';
import { Sleep } from '../utils/utils';
import { UsingLock } from '../utils/utils';
import { SME_CON } from './api-connection';
import { Wait_For_Auth } from '../utils/utils';


// const TOKEN_EXPIRY =  process.env.REACT_APP_TOKEN_EXPIRY; // 60
// const TOKEN_EXPIRY = 1; // 60
const SYNC_AUTH_URL = process.env.REACT_APP_SYNC_AUTH_URL;
const SERVER_AUTH_URL = SYNC_AUTH_URL + '/auth/login';
const SERVER_LOGOUT_URL = SYNC_AUTH_URL + '/auth/logout';
const SERVER_REFRESH_URL = SYNC_AUTH_URL + '/auth/token';
const SERVER_AUTH_CHECK_URL = SYNC_AUTH_URL + '/check';

// DEBUG
// For Local Host
// curl -k -X POST -H "Content-Type: application/json" -d '{"username":"admin","password":"12345gor"}' https://localhost:4000/auth/login
// curl -X POST -H "Content-Type: application/json" -d '{"username":"admin","password":"12345gor"}' https://treestock.tll.co.nz:4000/auth/login
// curl -k -X POST -H "Content-Type: application/json" -d '{"username":"admin","password":"12345gor"}' https://scoringmadeeasy.com/:4000/auth/login


const Auth = () => {
   const cookieOptions = {
      path: '/',
      maxAge: 7 * 24 * 60 * 60 * 1000, // Set the expiration time (7 days in this example)
      secure: true,
   }

   const get_auth_tokens = () => {
      const cookies = new Cookies();
      const token = sessionStorage.getItem('token') === 'null'? null:sessionStorage.getItem('token');
      const refreshToken = cookies.get('refreshToken');
      let deviceID = cookies.get('deviceID');
      if (typeof deviceID === 'undefined') {
         deviceID = crypto.randomUUID();
         cookies.set('deviceID', deviceID, cookieOptions);
      }

      // console.log('>>> get_auth_tokens <<<');
      // console.log('token: ', token);
      // console.log('refreshToken: ', refreshToken);
      // console.log('deviceID: ', deviceID);

      return {
         token: token,
         refreshToken: refreshToken,
         deviceID: deviceID,
      }
   }

   const set_auth_tokens = (parm = {}) => {
      if (typeof parm.token != 'undefined') {
         sessionStorage.setItem('token', parm.token);
      }
      if (typeof parm.refreshToken != 'undefined') {
         const cookies = new Cookies();
         cookies.set('refreshToken', parm.refreshToken, cookieOptions);
      }
   }

   const init_auth = () => {
      return new Promise(async (resolve, reject) => {
         try {
            // console.warn('-----init_auth------');
            let loggedIn = false
            const authTokens = get_auth_tokens();

            if (authTokens.token !== null) {
               loggedIn = await login_check(authTokens.token);
            }
            if (!loggedIn && typeof authTokens.refreshToken !== 'undefined' && authTokens.refreshToken !== null) {
               loggedIn = await refresh_jwt_token(authTokens.refreshToken);
            }

            resolve(loggedIn);
         } catch (error) {
            console.error('ERROR - init_auth: ', error);
            reject(false);
         }
      });
   }

   const login_check = (token) => {
      return new Promise((resolve, reject) => {
         try {
            $.ajax({
               url: SERVER_AUTH_CHECK_URL,
               type: "POST",
               data: JSON.stringify({ "token": token }),
               contentType: 'application/json; charset=utf-8',
               dataType: 'json',
               headers: {
                  Authorization: 'Bearer ' + token
               },
               success: function (response) {
                  // console.log('login_check response: ', response);
                  if (response.message === 'Logged In') {
                     resolve(true);
                  } else {
                     resolve(false);
                  }
               },
               error: function (errorMessage) {
                  // console.error('login_check errorMessage: ', errorMessage);
                  if (errorMessage.status === 0) {
                     const authTokens = get_auth_tokens();
                     if (typeof authTokens.refreshToken === 'string' && authTokens.refreshToken.length === 128) {
                        resolve(true);
                     }
                  } else {
                     resolve(false);
                  }
               },
               timeout: 5000
            });
         } catch (error) {
            console.error('login_check error: ', error);
            resolve(false);
         }
      });
   }

   const login = (credentials) => {
      return new Promise((resolve, reject) => {
         try {
            // console.log('%c [BACKEND - AUTH] ' + '>>> LOGIN <<<', 'background: #222; color: lightblue');

            const authTokens = get_auth_tokens();
            credentials.deviceID = authTokens.deviceID;

            $.ajax({
               url: SERVER_AUTH_URL,
               type: "POST",
               data: JSON.stringify(credentials),
               contentType: 'application/json; charset=utf-8',
               dataType: 'json',
               success: function (response) {
                  // console.log('Login response: ', response);
                  if (response.message === 'Logged in successfully') {
                     set_auth_tokens({
                        token: response.token,
                        refreshToken: response.refreshToken,
                     });
                     resolve(true);
                  } else {
                     resolve(false);
                  }
               },
               error: function (errorMessage) {
                  console.error('LOGIN errorMessage: ', errorMessage);
                  if (typeof errorMessage.statusText !== 'undefined' && errorMessage.statusText === 'timeout') {
                     resolve('Timeout');
                  } else if (typeof errorMessage.responseJSON === 'undefined') {
                     resolve('Connection Error')
                  } else {
                     resolve(errorMessage.responseJSON.message);
                  }
               },
               timeout: 5000
            });
         } catch (error) {
            console.error('LOGIN error: ', error);
            reject(false);
         }
      });
   }

   const logout = () => {
      return new Promise((resolve, reject) => {
         try {
            console.log('%c [BACKEND - AUTH] >>> LOGOUT <<<', 'background: #222; color: red');
            const authTokens = get_auth_tokens();
            $.ajax({
               url: SERVER_LOGOUT_URL,
               type: "POST",
               data: JSON.stringify({ "refreshToken": authTokens.refreshToken }),
               contentType: 'application/json; charset=utf-8',
               dataType: 'json',
               success: function (response) {
                  console.log('Logout response: ', response);

                  if (response.message === 'User logged out successfully') {
                     set_auth_tokens({
                        token: null,
                        refreshToken: null,
                     });
                     resolve(true);
                  } else {
                     resolve(false);
                  }
               },
               error: function (errorMessage) {
                  console.error('LOGOUT errorMessage: ', errorMessage.responseJSON.message);
                  resolve(false);
               },
               timeout: 5000
            });
         } catch (error) {
            console.error('LOGOUT error: ', error);
            reject(false);
         }
      });
   }

   const refresh_jwt_token = (refreshToken) => {
      return new Promise((resolve, reject) => {
         try {
            // console.log('%c [BACKEND - AUTH] ' + '>>> REFRESH_LOGIN <<<', 'background: #222; color: lightblue');
            const authTokens = get_auth_tokens();
            const deviceID = authTokens.deviceID;

            $.ajax({
               url: SERVER_REFRESH_URL,
               type: "POST",
               data: JSON.stringify({
                  "refreshToken": refreshToken,
                  "deviceID": deviceID,
               }),
               contentType: 'application/json; charset=utf-8',
               dataType: 'json',
               success: function (response) {
                  if (response.message === 'New access token generated') {
                     set_auth_tokens({
                        token: response.token,
                     });
                     resolve(true);
                  } else {
                     resolve(false);
                  }
               },
               error: function (errorMessage) {
                  console.error('refresh_jwt_token Jquery: ', errorMessage);
                  resolve(false);
               },
               timeout: 5000
            });
         } catch (error) {
            console.error('refresh_jwt_token Catch: ', error);
            resolve(false);
         }
      });
   }


   const get_token = () => {
      return new Promise(async (resolve, reject) => {
         UsingLock(LOCKS.get_token_lock, async () => {
            const result = await get_token_promise();
            resolve(result);
         });
      });
   }



   const get_token_promise = () => {
      return new Promise(async (resolve, reject) => {
         try {
            const authTokens = get_auth_tokens();
            const login_result = await login_check(authTokens.token);

            // >>> If token has expired request a refresh before returning
            if (login_result === false) {
               const result = await refresh_jwt_token(authTokens.refreshToken);
               const authTokens_retry = get_auth_tokens();
               resolve(JSON.parse(JSON.stringify(authTokens_retry)));
               return;
            }
            resolve(JSON.parse(JSON.stringify(authTokens)));
         } catch (error) {
            console.log('Get_token: ', error)
         }
      });
   }

   const init = () => { return  init_auth(); }


   return { init, login, logout, get_token }
}

const build_sme_con = () => {
   return new Promise(async (resolve) => {
      const authObj = Auth();
      await Wait_For_Auth(authObj);
      authObj.init().then(async (authResult) => {
         const sme_con = new SME_CON(authObj);
         while (!sme_con.ready) {
            if (sme_con.client === false) {
               resolve(null);
               return
            }
            await Sleep(50);
         }
         resolve(sme_con);
      }).catch(() => {
         resolve(null);
      });
   });
}



export { Auth, build_sme_con }
export default Auth; 